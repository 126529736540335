import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Centered from '../elements/Centered';

export default function Loading(props) {
    return (
        <Centered>
            <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>
        </Centered>
    );
}