export default {
    colors: {
        white: '#FFFFFF',
        yellow: '#FFAD00',
        body: '#555555',
        title: '#363740',
        pageBackground: '#ededed',
        border: '#D3D3D3',
        placeholder: '#BBBBBB',
        smallText: '#666666',
        link: '#0071EB', // A slightly darker blue to pass ally test

    },
    fonts: {
        normal: `"Source Sans Pro", "Helvetica Neue", Arial`,
        semibold: `"Source Sans Pro", "Helvetica Neue", Arial`,
        heading: `'Playfair Display',serif;`
    },
    fontSize: {
        body: '16px',
        form: '15px',
        formHeading: '12px',
        small: '14px',
        h1: '32px',
        h2: '25px',
        h3: '20px'
    },
    fontSizeXs: {
        h1: '23px',
        h2: '20px',
        h3: '16px',
    },
    fontSizeSm: {
        h1: '28px'
    },
    margins: {
        base: '50px',
        halfBase: '25px',
        
    },
    /*
    breakpoint:{
        xxsWidth: '468px',s
        xsWidth: '767px',
        smWidth: '991px',
        mdWidth: '1199px',
        lgWidth: '1200px',
        xlWidth: '2000px',

        video: 576px
    }*/
}