import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import AuthHelper from '../../helpers/Token';
import RenewToken from '../common/renewToken';

function PrivateRoute({ children, location, fakeAuth, ...rest }) {

    let token = AuthHelper.token.get();

    if(AuthHelper.token.isExpired(token) && token)
        RenewToken(token)

    else if(token===null)
        return (
            <Redirect
                to={{
                    pathname: `/`,
                    state: { from: location }
                }}
            />
        );

    return (
        <Route
            {...rest}
            render={children }
        />
    );
}

export default PrivateRoute