var jwtDecode = require('jwt-decode');

const TOKEN_NAME = 'jwt';

const token = {
    set: (token) => {
        localStorage.setItem(TOKEN_NAME, token);
        window.location.href = `/courses`;
    },
    get: () => {
        return localStorage.getItem(TOKEN_NAME)
    },
    remove: () => {
        localStorage.removeItem(TOKEN_NAME);
        window.location.href = `/`;
    },
    isExpired: (tokenToCheck) => {
        try {
            if(!tokenToCheck){
                tokenToCheck = token.get();
            }
            const decoded = jwtDecode(tokenToCheck);

            return (decoded.exp < Date.now() / 1000);
        }
        catch (err) {
            return true; //false;
        }
    },
    decode: (atoken) =>{
        if(!atoken){
            atoken = token.get();
        }
        return jwtDecode(atoken);
    }
}
export default { token };