import { createGlobalStyle } from 'styled-components';
import c from './_constants';

export default createGlobalStyle`
    html {
        height: 100%;
        padding: 0;
        margin: 0;
    }
    body {
        padding: 0;
        margin: 0;
        color: ${c.colors.body};
        font-size: ${c.fontSize.body};
        height: inherit;
        font-family: ${c.fonts.normal};
        line-height: 1.5;
    }
    body.grey-background {
        
        @media (min-width: 769px) {
            background: ${c.colors.pageBackground};
        }
    }

    h1, h2, h3, h4, h5, h6,
    .h1, .h2, .h3, .h4, .h5, .h6 {
        font-family: ${c.fonts.heading};
        color: ${c.colors.title};
        font-weight: normal;
    }
    h1, .h1 {
        font-size: ${c.fontSize.h1};
    }
    h2, .h2 {
        font-size: ${c.fontSizeXs.h2};
        font-family: ${c.fonts.normal};

        @media (min-width: 991px) {
            font-size: ${c.fontSize.h2};
        }
    }
    h3, .h3 {
        font-family: ${c.fonts.normal};
        color: ${c.colors.body};
        font-weight: 600;
        font-size: ${c.fontSizeXs.h3};
        margin-bottom: 15px;

        @media (min-width: 991px) {
            font-size: ${c.fontSize.h3};
            margin-bottom: 20px;
        }

        &.in-article {
            margin-top: 25px;
            margin-bottom: -15px;
            font-size: 18px;

            @media (min-width: 991px) {
                margin-top: 30px;
            }
        }
    }
    label {
        color: ${c.colors.body};

        a {
            color: ${c.colors.link};
            text-decoration: underline;
        }
    }
    
    p, ul, ol {
        margin-top: 20px; // Remove this at some point (and adjust accordingly)
        margin-bottom: 20px;

        a {
            color: ${c.colors.link};
            text-decoration: underline;
        }
    }
    h2 {
        margin-top: 25px;
        margin-bottom: -10px;
    }

    ul.list-styled {
        margin: 0; padding: 0;
        margin-left: 20px;
        list-style-type: circle;

        @media (min-width: 769px) {
            margin-left: 30px;
        }

        li {
            margin-bottom: 6px;

            @media (min-width: 769px) {
                margin-bottom: 10px;
            }
        }
    }

    .alert-danger {
        border: 1px dashed #721c24;
    }


    /* FORM */

    .form-group {
        margin-bottom: 1.2rem;

        label {
            margin-bottom: 5px;
            color: #555;
        }
    }

    .form-control, select.form-control {
        color: #555;
        background: #fff;
        border: 1px solid ${c.colors.border};
        border-radius: 3px;
        line-height: 1.3em;
        padding: 6px 8px 5px;
        height: auto;
        box-shadow: none;

        &:active, &:focus {
            border-color: #979797;
            /* border-color: ${c.colors.yellow}; */
            /* box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(255, 0, 0, 0.6); */
        }
    }

    input.form-control:read-only {
        background: #f1f1f1;
    }

    select.form-control {
        background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSdibGFjaycgaGVpZ2h0PScyNCcgdmlld0JveD0nMCAwIDI0IDI0JyB3aWR0aD0nMjQnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggZD0nTTcgMTBsNSA1IDUtNXonLz48cGF0aCBkPSdNMCAwaDI0djI0SDB6JyBmaWxsPSdub25lJy8+PC9zdmc+);
        background-repeat: no-repeat;
        background-position: top 3px right 4px;
        appearance: none;

        &::-ms-expand {
            display: none;
        }
    }

    .form-control::placeholder{
        color: ${c.colors.placeholder};
    }


    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active  {
        -webkit-box-shadow: 0 0 0 30px white inset !important; 
    }

    /*Change text in autofill textbox*/
    input:-webkit-autofill {
        -webkit-text-fill-color: #555 !important;
    }

   input:not([type=checkbox]):not([type=radio]){
       -webkit-appearance: none; // To remove inner shadow on iphone safari
   }


   /* SKIP TO CONTENT */
   .skip-navigation {
        position: absolute;
        left: -9999px;
        top: 0;
        z-index: 5;
        color: black;
        background-color: #fff;
        border: 1px solid #ccc;
        padding: 1em;

        &:focus {
            left: 0;
            display: inline-block;
        }
    }
`
