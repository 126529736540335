import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import ApolloClient from 'apollo-boost';
import { InMemoryCache } from 'apollo-cache-inmemory';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Loading from './components/common/Loading';
import PrivateRoute from './components/common/PrivateRoute';
import GlobalStyle from './components/elements/_global';
import Config from './config';
//import 'bootstrap/dist/css/bootstrap.min.css';
import './components/elements/_customBootstrap.sass';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

require('typeface-source-sans-pro')
require('typeface-playfair-display')

const cache = new InMemoryCache();
const client = new ApolloClient({
	uri: Config.gqlUrl,
	cache,
	request: (operation) => {
		const token = localStorage.getItem('jwt')
		operation.setContext({
		  headers: {
			authorization: token ? `Bearer ${token}` : ''
		  }
		})
	  }	
});

const Home 				= React.lazy(()=>import('./pages/Home'));
const Courses           = React.lazy(()=>import('./pages/Courses'));
const Course 			= React.lazy(()=>import('./pages/Course'));
const Question			= React.lazy(()=>import('./pages/Question'));
const Finished			= React.lazy(()=>import('./pages/Finished'));
const Login             = React.lazy(()=>import('./pages/Login'));
const Register			= React.lazy(()=>import('./pages/Registration'));
const Profile			= React.lazy(()=>import('./pages/Profile'));
const ForgotPassword	= React.lazy(()=>import('./pages/ForgotPassword'));
const ResetPassword		= React.lazy(()=>import('./pages/ResetPassword'));
const FourOhFour		= React.lazy(()=>import('./pages/FourOhFour'));
const About				= React.lazy(()=>import('./pages/About'));
const Winners			= React.lazy(()=>import('./pages/Winners'));

ReactDOM.render(
	<React.StrictMode>
		<GlobalStyle/>
		<ApolloProvider client={client}>
			<Router forceRefresh={true}>
				<Suspense fallback={<Loading/>}>
					<Switch>
						<PrivateRoute exact path="/home" component={(props) => <Home loggedin={true}/>}/>
						<PrivateRoute exact path="/about" component={(props) => <About/>}/>
						<PrivateRoute exact path="/winners" component={(props) => <Winners/>}/>
						<PrivateRoute exact path="/courses" component={(props) => <Courses/>}/>
						<PrivateRoute exact path="/course/:courseId" component={(props) => <Course/>}/>
						<PrivateRoute exact path="/course/:courseId/finished" component={(props) => <Finished/>}/>
						<PrivateRoute exact path="/course/:courseId/:questionNumber" component={(props) => <Question/>}/>
						<PrivateRoute exact path="/logout" component={(props) => <Login logout={true}/>}/>
						<PrivateRoute exact path="/profile" component={(props) => <Profile/>}/>
						<Route exact path="/register" component={(props) => <Register/>}/>
						<Route exact path="/forgot-password" component={(props) => <ForgotPassword/>}/>
						<Route exact path="/reset-password" component={(props) => <ResetPassword/>}/>
						<Route exact path="/login" component={(props) => <Login/>}/>
						<Route exact path="/" component={(props) => <Home/>}/>
						<Route component={FourOhFour} />
					</Switch>
				</Suspense>
			</Router>
		</ApolloProvider>
  	</React.StrictMode>,
  	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
